// Global styles.

@import "typography";
@import "colors";
@import "devices";
@import "~@csstools/normalize.css";
@import "material-shadows/material-shadows";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

// Global Styles.
html,
body {
  width: 100%;
  margin: 0;
  background: $body-color;
  min-width: $phone-small;
  text-align: center;
}

a {
  color: $burgundy;
  text-decoration: none;
}

.sectionMargins {
  margin: 16px auto 16px auto;
  max-width: 1024px;
}

// Card styles.
.card {
  @extend .sectionMargins;
  padding: 24px 16px;
  background: white;
  @include z-depth(2);
  position: relative;
}

@media #{$screen-mobile} {
  .sectionMargins {
    margin: 16px;
  }
  .card {
    padding: 16px;
  }
}
