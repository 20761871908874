// Color variables.

// Colors from https://www.color-hex.com/color-palette/11299.
$tan: #f9d4af;
$pink: #b89090;
$red: #763131;
// $burgundy: #4f0505;
$navy: #08082f;

// Body colors.
$body-color: #fcfcfc;

// Material Color Scheme.
$primary: #7b241c;
$primary-variant: #992e29;
$secondary: #3d5369;
$secondary-variant: #1c2c3b;
$burgundy: $primary;
